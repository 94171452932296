import {SimpleItem} from "../types";

export const getReviewFormFields = (isBuyCase: boolean) => [
  {
    type: 'rating',
    fieldName: 'rate',
    title: `Rate the ${isBuyCase ? 'seller:' : 'buyer'}`,
    required: true,
  },
  {
    type: 'textArea',
    placeholder: 'Write a review here',
    fieldName: 'review',
    required: true,
    from: 5,
    mode: 'low',
  },
];

export const getReportFieldProps = (reasons: SimpleItem[]) => [
  {
    type: 'radioBox',
    fieldName: 'reason_id',
    options: reasons,
  },
];
