import { configureStore } from '@reduxjs/toolkit';
import appDataReducer from './appDataSlice';
import adminPanelReducer from './adminPanelSlice';

export const store = configureStore({
  reducer: {
    appData: appDataReducer,
    adminPanelData: adminPanelReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
