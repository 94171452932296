import { useAppDispatch, useAppSelector, clearStateSnackBar, selectSnackbarData } from 'store';
import { Snackbar } from './Snackbar';

export const ReduxDrivenSnackbar = () => {
  const dispatch = useAppDispatch();
  const snackProps = useAppSelector(selectSnackbarData);
  if (!snackProps) return null;

  const onClose = () => {
    dispatch(clearStateSnackBar());
  };

  return <Snackbar {...snackProps} onClose={onClose} />;
};
