export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W_]){1,})(?!.*\s).{8,}$/;

export const SANITIZED_TEXT_REGEX = /^[A-Za-z\d ,-]+$/gm;
export const PLAIN_TEXT_REGEX = /^[A-Za-z ,.'-/0-9]+$/gm;
export const SIZE_REGEX = /^[A-Za-z .'-/0-9]+$/gm;
export const QUESTION_TEXT_REGEX = /^[A-Za-z ?,.-/0-9]+$/gm;
export const DIGIT_REGEX = /^\d+$/gm;
export const LATIN_LETTERS__REGEX = /^[A-Za-z]/gm;
export const NAME_REGEX = /^[A-Za-z][\s\-_'A-Za-z]*$/gm;
export const BRAND_NAME_REGEX = /^[A-Za-z &,.'-]+$/gm;
export const USER_NAME_REGEX = /^[-_'A-Za-z.0-9]*$/gm;
export const DIGITS_WITH_APOSTROPHE = /(\d*\.?\d+)$/gm;
export const DIMENSION_REGEX = /(\d*\.?\d+)$/gm;
export const ASCII_REGEX = /([\x00-\x7F]+)$/gm;
export const PRICE_REGEX = /^\d+(.\d{1,2})?$/;
export const NUMERIC_TEXT_REGEX = /^\d+(.\d+)?$/;
export const HSC_REGEXP = /^[0-9A-Za-z\.]*$/;
