import {
  getCodeVerificationErrorMessage,
  getConfirmPasswordErrorMessage,
  getEmailErrorMessage,
  getEmptyPasswordErrorMessage,
  getHeightErrorMessage,
  getLocationsErrorMessage,
  getPasswordErrorMessage,
  getPriceErrorMessage,
  getProperNameErrorMessage,
  getTextAreaErrorMessage,
  getUserNameErrorMessage,
  getWeightErrorMessage,
  isNumber,
  getEmptyFieldErrorMessage,
  getTagErrorMessage,
} from 'utils/validators';
import { formatUnicodeTimeToImperial } from 'utils/formatters';
import { ValidateType } from 'types';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const DAY_NAMES = ['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa', 'Su'];

export const sortIconsSrcMap: Record<string, string> = {
  none: '/icons/arrows/SortDoubleVertical.svg',
  asc: '/icons/arrows/CaretUpGrey.svg',
  desc: '/icons/arrows/CaretDownGrey.svg',
};

export const validatorMap: Record<string, ValidateType> = {
  getEmailErrorMessage: getEmailErrorMessage,
  getProperNameErrorMessage: getProperNameErrorMessage,
  getConfirmPasswordErrorMessage: getConfirmPasswordErrorMessage,
  getTextAreaErrorMessage: getTextAreaErrorMessage,
  getUserNameErrorMessage: getUserNameErrorMessage,
  getLocationsErrorMessage: getLocationsErrorMessage,
  getTagErrorMessage: getTagErrorMessage,
  getPasswordErrorMessage: getPasswordErrorMessage,
  getEmptyPasswordErrorMessage: getEmptyPasswordErrorMessage,
  isNumber: isNumber,
  getEmptyFieldErrorMessage: getEmptyFieldErrorMessage,
  getCodeVerificationErrorMessage: getCodeVerificationErrorMessage,
  getPriceErrorMessage: getPriceErrorMessage,
  getHeightErrorMessage: getHeightErrorMessage,
  getWeightErrorMessage: getWeightErrorMessage,
};

export const TIME_PICKER_OPTIONS = Array.apply(null, Array(25)).map((_, idx) => {
  const unicodeTimeString = `${idx <= 9 ? '0' + idx : idx}:00`;
  return {
    id: unicodeTimeString,
    title: formatUnicodeTimeToImperial(unicodeTimeString),
  };
});

export const HOSTNAMES = {
  QA: 'qa.sendy.io',
  DEV: 'dev.sendy.io',
  APP: 'app.sendy.io',
  STAGE: 'stage.sendy.io',
  LOCALHOST: 'localhost',
  LOCALHOST3000: 'localhost:3000'
}

export const  STATUS_ICON_MAP = {
  shipping_waiting: '/icons/media/PauseCircleYellow.svg',
  renting_scheduled: '/icons/media/StopCirclePurple.svg',
  in_transit: '/icons/TruckYellow.svg',
  delivered: '/icons/maps/HouseLineBlue.svg',
  renting_process: '/icons/arrows/ArrowCircleUpRightGold.svg',
  renting_overdue: '/icons/arrows/ArrowCircleUpRightRed.svg',
  success: '/icons/system/CheckCircleColor.svg',
  canceled: '/icons/system/XCircleRed.svg',
  overdue_canceled: '/icons/system/XCircleRed.svg',
  renting_overdue_canceled: '/icons/system/XCircleRed.svg',
  return_shipping_waiting: '/icons/media/PauseCircleYellow.svg',
  return_in_transit: '/icons/TruckYellow.svg',
  return_delivered: '/icons/maps/HouseLineBlue.svg',
  return_picked_up_delivery: '/icons/arrows/ArrowCircleDownGold.svg',
  returned: '/icons/arrows/ArrowUUpLeftBlue.svg',
  failed: '/icons/system/XCircleRed.svg',
};

export const  STATUS_COLOR_MAP = {
  shipping_waiting: '#EFC148',
  renting_scheduled: '#AC6CD4',
  in_transit: '#EFC148',
  delivered: '#6CD4AF',
  renting_process: '#efc148',
  renting_overdue: '#EA4335',
  success: '#6CC4D4',
  canceled: '#EA4335',
  overdue_canceled: '#EA4335',
  renting_overdue_canceled: '#EA4335',
  return_shipping_waiting: '#EFC148',
  return_in_transit: '#EFC148',
  return_delivered: '#6CD4AF',
  return_picked_up_delivery: '#EFC148',
  returned: '#6CD4AF',
  failed: '#EA4335',
};

export const  STATUS_TITLE_MAP = {
  shipping_waiting: 'Order Placed',
  renting_scheduled: 'Renting Scheduled',
  in_transit: 'In Transit',
  delivered: 'Delivered',
  renting_process: 'Rent Process',
  renting_overdue: 'Rent Overdue',
  success: 'Completed',
  canceled: 'Canceled',
  overdue_canceled: 'Overdue Canceled',
  renting_overdue_canceled: 'Rent Overdue Canceled',
  return_shipping_waiting: 'Return Placed',
  return_in_transit: 'Return In Transit',
  return_delivered: 'Return Delivered',
  return_picked_up_delivery: 'Return Picked Up',
  returned: 'Returned',
  failed: 'Failed',
};
