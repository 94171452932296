import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ModalProps, CurrentUser } from 'types';
import { removeLogInDataFromBrowser } from 'utils/localStoreTools';
import { getSignUpSteps } from 'utils/services';
import { SnackbarProps } from 'components/Snackbar';

export type appDataState = {
  renderStepper?: boolean;
  user?: CurrentUser | null;
  passedSteps?: string[];
  currentSignUpStep?: number;
  signInSlides?: {
    image: string;
    title: string;
    description: string;
  }[];
  modal?: ModalProps | null;
  snackbar?: SnackbarProps | null;
};

const initialState: appDataState = {};

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setAppData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setUser: (state, { payload }) => {
      return { ...state, user: payload };
    },
    setSignUpStep: (state, action) => {
      const { user } = state;
      const signUpSteps = { ...getSignUpSteps(user || {}), current: action.payload };

      return { ...state, user: { ...user, signUpSteps } };
    },
    clearUser: (state) => {
      removeLogInDataFromBrowser();
      return { ...state, user: null };
    },
    openModal: (state, action) => {
      return { ...state, modal: action.payload };
    },
    closeModal: (state) => {
      return { ...state, modal: null };
    },
    openSnackBar: (state, { payload }) => {
      return { ...state, snackbar: { ...payload } };
    },
    clearStateSnackBar: (state) => {
      return {
        ...state,
        snackbar: null,
      };
    },
  },
});

export const {
  setAppData,
  clearUser,
  openModal,
  closeModal,
  setUser,
  setSignUpStep,
  openSnackBar,
  clearStateSnackBar,
} = appDataSlice.actions;

export const selectCurrentUser = (state: RootState) => state.appData.user;
export const selectModalData = (state: RootState) => state.appData.modal;
export const selectSnackbarData = (state: RootState) => state.appData.snackbar;

export default appDataSlice.reducer;
