import {DropdownOption} from "../types";

export const COMPARING_FIELDS_MAP: Record<string, string> = { password_confirmation: 'password' };

export const STATE_OPTIONS: DropdownOption[] = [
  { value: 'Alabama', id: 'AL', label: 'Alabama' },
  { value: 'Alaska', id: 'AK', label: 'Alaska' },
  { value: 'Arizona', id: 'AZ', label: 'Arizona' },
  { value: 'Arkansas', id: 'AR', label: 'Arkansas' },
  { value: 'California', id: 'CA', label: 'California' },
  { value: 'Colorado', id: 'CO', label: 'Colorado' },
  { value: 'Connecticut', id: 'CT', label: 'Connecticut' },
  { value: 'Delaware', id: 'DE', label: 'Delaware' },
  { value: 'Florida', id: 'FL', label: 'Florida' },
  { value: 'Georgia', id: 'GA', label: 'Georgia' },
  { value: 'Hawaii', id: 'HI', label: 'Hawaii' },
  { value: 'Idaho', id: 'ID', label: 'Idaho' },
  { value: 'Illinois', id: 'IL', label: 'Illinois' },
  { value: 'Indiana', id: 'IN', label: 'Indiana' },
  { value: 'Iowa', id: 'IA', label: 'Iowa' },
  { value: 'Kansas', id: 'KS', label: 'Kansas' },
  { value: 'Kentucky', id: 'KY', label: 'Kentucky' },
  { value: 'Louisiana', id: 'LA', label: 'Louisiana' },
  { value: 'Maine', id: 'ME', label: 'Maine' },
  { value: 'Maryland', id: 'MD', label: 'Maryland' },
  { value: 'Massachusetts', id: 'MA', label: 'Massachusetts' },
  { value: 'Michigan', id: 'MI', label: 'Michigan' },
  { value: 'Minnesota', id: 'MN', label: 'Minnesota' },
  { value: 'Mississippi', id: 'MS', label: 'Mississippi' },
  { value: 'Missouri', id: 'MO', label: 'Missouri' },
  { value: 'Montana', id: 'MT', label: 'Montana' },
  { value: 'Nebraska', id: 'NE', label: 'Nebraska' },
  { value: 'Nevada', id: 'NV', label: 'Nevada' },
  { value: 'New Hampshire', id: 'NH', label: 'New Hampshire' },
  { value: 'New Jersey', id: 'NJ', label: 'New Jersey' },
  { value: 'New Mexico', id: 'NM', label: 'New Mexico' },
  { value: 'New York', id: 'NY', label: 'New York' },
  { value: 'North Carolina', id: 'NC', label: 'North Carolina' },
  { value: 'North Dakota', id: 'ND', label: 'North Dakota' },
  { value: 'Ohio', id: 'OH', label: 'Ohio' },
  { value: 'Oklahoma', id: 'OK', label: 'Oklahoma' },
  { value: 'Oregon', id: 'OR', label: 'Oregon' },
  { value: 'Pennsylvania', id: 'PA', label: 'Pennsylvania' },
  { value: 'Rhode Island', id: 'RI', label: 'Rhode Island' },
  { value: 'South Carolina', id: 'SC', label: 'South Carolina' },
  { value: 'South Dakota', id: 'SD', label: 'South Dakota' },
  { value: 'Tennessee', id: 'TN', label: 'Tennessee' },
  { value: 'Texas', id: 'TX', label: 'Texas' },
  { value: 'Utah', id: 'UT', label: 'Utah' },
  { value: 'Vermont', id: 'VT', label: 'Vermont' },
  { value: 'Virginia', id: 'VA', label: 'Virginia' },
  { value: 'Washington', id: 'WA', label: 'Washington' },
  { value: 'West Virginia', id: 'WV', label: 'West Virginia' },
  { value: 'Wisconsin', id: 'WI', label: 'Wisconsin' },
  { value: 'Wyoming', id: 'WY', label: 'Wyoming' },
  { id: 'DC', label: 'District of Columbia' },
  { id: 'AS', label: 'American Samoa' },
  { id: 'GU', label: 'Guam' },
  { id: 'MP', label: 'Northern Mariana Islands' },
  { id: 'PR', label: 'Puerto Rico' },
  { id: 'UM', label: 'United States Minor Outlying Islands' },
  { id: 'VI', label: 'Virgin Islands US' },
];

export const COUNTRY_OPTIONS: DropdownOption[] = [
  {
    id: 'US',
    label: 'USA',
    value: 'USA',
  },
  {
    id: 'CA',
    label: 'Canada',
    value: 'CAN',
  },
];

export const COUNTRY_SELECT: DropdownOption[] = [
  {
    id: 'US',
    label: 'USA',
    value: 'USA',
  },
  {
    id: 'CA',
    label: 'Canada',
    value: 'CAN',
  },
];

export const CURRENCY_SELECT: DropdownOption[] = [
{
    id: 'US',
    label: 'USD',
    value: 'USD',
  },
  {
    id: 'CA',
    label: 'CAD',
    value: 'CAD',
  },
];

export const PROVINCE_OPTIONS: DropdownOption[] = [
  {
    id: 'AB',
    label: 'Alberta',
  },
  {
    id: 'BC',
    label: 'British Columbia',
  },
  {
    id: 'MB',
    label: 'Manitoba',
  },
  {
    id: 'NB',
    label: 'New Brunswick',
  },
  {
    id: 'NL',
    label: 'Newfoundland and Labrador',
  },
  {
    id: 'NT',
    label: 'Northwest territories',
  },
  {
    id: 'NS',
    label: 'Nova Scotia',
  },
  {
    value: 'NU',
    label: 'Nunavut',
  },
  {
    id: 'ON',
    label: 'Ontario',
  },
  {
    id: 'PE',
    label: 'Prince Edward Island',
  },
  {
    id: 'QC',
    label: 'Quebec',
  },
  {
    id: 'SK',
    label: 'Saskatchewan',
  },
  {
    id: 'YT',
    label: 'Yukon',
  },
];

export const STATUSES_REPLACEMENT_MAP: Record<string, string> = {
  shipping_waiting: 'Order Placed',
  return_shipping_waiting: 'Return Placed',
  success: 'Completed',
};
