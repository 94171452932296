import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { AdminPanelDashboardData, AdminPanelStoreData } from 'types';
import { getAdminPanelBoard } from 'api';
import {getCookie, storeFiltersOnBrowser, storeTabOnBrowser} from 'utils/localStoreTools';

const initialState: AdminPanelStoreData = {
  isLoading: false,
  filters: getCookie('filters') || {},
  activeTab: getCookie('tab'),
};

export const getFilteredAdminPanel = createAsyncThunk(
  'api/getAdminPanelBoard',
  async (requestData: any, thunkAPI): Promise<AdminPanelDashboardData> => {
    // @ts-ignore
    const { api, dataSanitizer, permanentFilters } = thunkAPI.getState().adminPanelData;
    return await getAdminPanelBoard(api, { ...permanentFilters, ...requestData }, {}, dataSanitizer);
  },
);

export const adminPanelSlice = createSlice({
  name: 'adminPanelData',
  initialState,
  reducers: {
    setAdminPanelState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setAdminPanelFilters: (state, action) => {
      storeFiltersOnBrowser(action.payload);
      return { ...state, filters: action.payload };
    },
    setActiveTab: (state, action) => {
      storeTabOnBrowser(action.payload);
      return { ...state, activeTab: action.payload };
    },
    setApi: (state, action) => {
      return { ...state, api: action.payload };
    },
    setSanitizer: (state, action) => {
      return { ...state, dataSanitizer: action.payload };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFilteredAdminPanel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFilteredAdminPanel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      });
  },
});

export const { setAdminPanelState, setAdminPanelFilters, setActiveTab, setApi, setSanitizer } = adminPanelSlice.actions;

export const selectAdminPanelData = (state: RootState) => state.adminPanelData;
export const selectAdminPanelPages = (state: RootState) => state.adminPanelData.meta;
export const selectAdminPanelFilters = (state: RootState) => state.adminPanelData.filters;
export const selectActiveTab = (state: RootState) => state.adminPanelData.activeTab;

export default adminPanelSlice.reducer;
