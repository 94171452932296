export const getSymbolLimitNotification = (from: string | number, to: string | number) =>
  `It should be from ${from}${to ? ` to ${to}` : ''} symbols.`;

export const getFieldLimitNotification = (from: string | number, to: string | number) =>
  `Field length should be from ${from} to ${to} symbols`;

export const getTagTitleLimitNotification = (to: string | number) =>
  `Title length of tag should be from 1 to ${to} symbols`;

export const getFirstSymbolNotification = (prefix: string) => prefix + ' should start from Latin letter';

export const getMaxValueLengthNotification = (to: string | number) => `Value should be up to ${to} symbols`;

export const getInvalidFieldNotification = (placeholder: string) => `Please, enter a valid ${placeholder}`;

export const getFromToFieldNotification = (placeholder: string = 'Value', from: string | number, to: string | number) => `${placeholder} should be from ${from} to ${to}.`;
