const append = (obj: any, form: any, namespace: string) => {
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      if (typeof obj[property] === 'object' && !(obj[property] instanceof File) && obj[property] !== null) {
        append(obj[property], form, formKey);
      } else {
        let value = obj[property];

        if (value === undefined) {
          continue;
        }

        if (value === null) {
          value = '';
        }

        if (typeof value === 'boolean') {
          value = String(+value);
        }

        form.append(formKey, value);
      }
    }
  }

  return form;
};

export const obj2FromData = (obj: any) => {
  const formData = new FormData();

  return append(obj, formData, '');
};
