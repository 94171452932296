import { useEffect, useState } from 'react';
import { Notification } from 'components/FormNotification';

import cx from 'classnames';
import styles from './Snackbar.module.scss';

export type SnackbarProps = {
  header: string;
  mode?: string;
  message?: string;
  onClose?: () => void;
  period?: number;
};

export const Snackbar = ({ mode, header, message, onClose, period = 3000 }: SnackbarProps) => {
  const [isShowed, setIsShowed] = useState(false);

  const closeSnackbar = () => {
    onClose && onClose();
  };

  const closer = () => {
    setTimeout(() => {
      setIsShowed(false);
      onClose && onClose();
    }, period);
  };

  const showSnackbar = () => {
    setIsShowed(true);
  };

  useEffect(() => {
    showSnackbar();
    closer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cx(styles.wrapper, { [styles.active]: isShowed })} onClick={closeSnackbar}>
      <Notification mode={mode} header={header} message={message} />
    </div>
  );
};
