import {CACHE} from "../consts";

export type CacheStoreItem = {
  value: string;
  expired: number;
};

export type CachePickDataArgs = {
  key: string;
  callback: (...params: any) => any;
  params?: any[];
  lifetime?: number;
  force?: boolean;
};

declare global {
  var cacheProvider: RequestsCacheProvider;
}

export class RequestsCacheProvider {
  private readonly lifetime: number;
  private readonly storage: Record<string, CacheStoreItem>;

  constructor(lifetime: number) {
    this.lifetime = lifetime || 360000;
    this.storage = {};

    if (globalThis?.cacheProvider) {
      return globalThis.cacheProvider;
    }
  }

  public async pickData(args: CachePickDataArgs) {
    const { key, callback, params = [], lifetime = CACHE.default, force } = args;
    const cachedValue = force ? null : this.checkCachedValue(key);
    if (cachedValue) {
      return cachedValue;
    }

    const response = await callback(...params);
    if (response.ok && !response.error) {
      this.saveValueToCache(key, response, lifetime || this.lifetime);
    }

    return response;
  }

  // todo - debug it before usage
  // public clearExpiredValues() {
  //   Object.entries(this.storage).map((entry) => {
  //     if (entry[1].expired < Date.now()) {
  //       delete this.storage[entry[0]];
  //     }
  //   });
  // }

  private saveValueToCache(key: string, value: any, lifetime: number) {
    this.storage[key] = {
      expired: Date.now() + lifetime,
      value: value,
    };
  }

  private checkCachedValue(key: string) {
    if (this.storage[key] && this.storage[key]?.expired > Date.now()) {
      return this.storage[key]?.value || null;
    }
    return null;
  }
}
